[dir=rtl] * {
  text-align: right!important;
}

.App {
  text-align: center;
}

.ant-form-item.ant-form-item-view-mode {
  margin-bottom: 0px!important;
}

.ant-form-item-label {
  padding: 0px!important;
}





.ant-form-vertical .ant-form-item {
  padding-bottom: 0px!important;
  margin-bottom: 10px!important;
}

#root > div > div.ant-tabs-bar {
  margin: 0px;
  padding: 0px;
}

.ant-layout {
  min-height: calc(100vh - 92px);
}
.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}
